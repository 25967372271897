function findKeywords() {

    function init(data) {
        var keywords = data.keywords,
            windowPathname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/'), window.location.pathname.length),
            pathname,
            str = $('.article-main-content').html();

        for (var i = 0; i < keywords.length; i++) {
            if (windowPathname === keywords[i].pathname) {
                pathname = keywords[i].pathname;
                var keyword = keywords[i].keyword,
                    regExp = new RegExp(keyword, 'i');
                str = str.replace(regExp, '<a class="keyword" href="' + keywords[i].targeturl + '" rel="nofollow"><span class="popup"><span class="wrapper"><img class="img-responsive" src="' + keywords[i].image + '" alt="' + keyword + '"><span class="caption">' + keywords[i].caption + '</span></span></span>' + keyword + '</a>');
            }
        }

        if (windowPathname === pathname) {
            $('.article-main-content').html(str);
        }
    }

    function error() {
        console.log('Could not load .JSON file');
    }

    $.getJSON("data/keywords.json", function (data) {
        init(data);
    }).fail(function () {
        error();
    });
}