(function ($) {
    $(document).ready(function () {
        function autoheightHeader() {
            var $windowWidth = $(window).width();
            // var $windowHeight = $(window).height();
            var $windowHeight = 858;
            if ($windowWidth >= 768 && $('body > header .jmod').length > 0) {
                $('body > header').css('height', $windowHeight);
            } else {
                $('body > header').css('height', 'auto');
            }
        }
        autoheightHeader();
        $(window).on('resize', autoheightHeader);
    });
}(jQuery));